.chat-page .bg-circle-1,
.chat-page .bg-circle-2, 
.chat-page .bg-circle-3, 
.chat-page .bg-circle-4 {
    position: absolute;
    border-radius: 50%;
    background-color: blue;
    filter: blur(80px);
    opacity: 0.3;
}

.chat-page .bg-circle-1 {
    width: 300px;
    height: 300px;
    top: 200px;
    right: -150px;
}

.chat-page .bg-circle-2 {
    width: 400px;
    height: 400px;
    bottom: -700px;
    left: -200px;
}

.chat-page .bg-circle-3 {
    width: 200px;
    height: 200px;
    bottom: -500px;
    left: 500px;
}


/* Smaller screens (e.g., tablets) */
@media (max-width: 768px) {
    .bg-circle-1 {
        width: 200px;
        height: 200px;
        /* Adjust position if needed */
    }

    .bg-circle-2 {
        width: 300px;
        height: 300px;
        /* Adjust position if needed */
    }
}

/* Very small screens (e.g., mobile phones) */
@media (max-width: 480px) {
    .bg-circle-1 {
        width: 150px;
        height: 150px;
        /* Adjust position if needed */
    }

    .bg-circle-2 {
        width: 200px;
        height: 200px;
        /* Adjust position if needed */
    }
}
